<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <v-select
    v-model="data"
    dense
    clearable
    small-chips
    solo
    flat
    :rules="rules"
    :multiple="multiple"
    :items="options"
    item-text="name"
    item-value="name"
    return-object
    @focus="createPrefectScrollbar"
  >
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-action>
          <v-checkbox
            :input-value="active"
            hide-details
            class="shrink mr-2 mt-0"
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-text-field
            v-if="item.id === 'other'"
            :label="$t('base.data.etc')"
            hide-details
            v-model="item.value"
            @click.stop
          ></v-text-field>

          <span v-else>{{ item.name }}</span>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import prefectScrollbar from 'components/scrollbar/prefectScrollbar.js'
import itemMixins from "./item";
export default {
  mixins: [itemMixins, prefectScrollbar],
  data: () => ({}),
  computed: {
    options() {
      const data = _cloneDeep(this.item.data) || [];
      if (this.canCustom) {
        data.push({
          id: "other",
          name: this.$t('base.data.etc'),
          value: "",
        });
      }
      return data;
    },
    multiple() {
      if (!this.item.meta) return false;
      return !!this.item.meta.select_multiple;
    },
    canCustom() {
      if (!this.item.meta) return false;
      return !!this.item.meta.select_custom_data;
    },
  },
  methods: {
    async createPrefectScrollbar() {
      await this.$helper.delay(0.3)
      this.$target = $('.v-menu__content')
      this.initScrollbar()
    },
  },
};
</script>